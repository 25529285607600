import React, { Component } from "react";

import "./image.css";

class Image extends Component {
  render() {
    return (
      <img className="image" src={this.props.src} alt={this.props.alt || ""} />
    );
  }
}
export default Image;
