import { Link } from "@reach/router";
import React, { Component } from "react";

import "./link.css";

class Button extends Component {
  render() {
    return (
      <Link
        className={`link ${this.props.className || ""}`}
        to={this.props.to || "/"}
        href={this.props.to || "/"}
      >
        {this.props.children}
      </Link>
    );
  }
}
export default Button;
