// Node Modules
import React from "react";
import { withSiteData } from "react-static";

// Global Components
import Image from "components/image/image";

// Local Components
import VideoPlayer from "./components/videoPlayer";

// Local CSS
import "./home.css";
import "./home-mobile.css";

// Local Assets
import Logo from "../../assets/images/logo.png";
import TestVideo from "../../assets/videos/hecha_test.mp4";
import BergsonistVideo from "./assets/hecha__final_compressed.mp4";
import BergsonistVideoPoster from "./assets/movie_poster_with_logo-min.png";

export default withSiteData(() => (
  <div>
    <div className="homepage">
      <p className="brandInfo">
        Our sustainably made gender-flexible apparel collection exhibited on
        9/8/18, featuring one-of-a-kind wearable paintings and simple activewear
        base-layers.
      </p>
      <div className="videoPlayerContainer">
        <div className="videoPlayerContainerInner">
          <VideoPlayer src={BergsonistVideo} poster={BergsonistVideoPoster} />
        </div>
      </div>
    </div>
  </div>
));
