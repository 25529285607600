import React from "react";

export const ShoppingCartContext = React.createContext();

class ShoppingCartProvider extends React.Component {
  state = {
    items: ["item"]
  };
  render() {
    return (
      <ShoppingCartContext.Provider value={this.state}>
        {this.props.children}
      </ShoppingCartContext.Provider>
    );
  }
}

export default ShoppingCartProvider;
