import React, { Component } from "react";

import "./button.css";

class Button extends Component {
  render() {
    return (
      <button
        className={`button ${this.props.color || "gray"} ${this.props
          .selected === true && "selected"}`}
      >
        {this.props.children}
      </button>
    );
  }
}
export default Button;
