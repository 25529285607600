// Node Modules
import React, { Component } from "react";

// GLobal Components
import Link from "components/link/link";

// Local CSS
import "./footer.css";
import "./footer-mobile.css";

// Local Assets
import EmailIcon from "./assets/email-icon.svg";

class Footer extends Component {
  render() {
    return (
      <div
        className={`footer ${this.props.absolute === true ? "absolute" : ""}`}
      >
        <div className="hecha">
          <Link to="/">
            <p>HECHA / 做 NYC</p>
          </Link>
        </div>
        <div className="links">
          <div className="subscribeForm">
            <div id="mc_embed_signup">
              <form
                action="https://hechanyc.us18.list-manage.com/subscribe/post?u=45ffa6ca8232b4a3a8c511844&amp;id=645d4d5362"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                noValidate
              >
                <div id="mc_embed_signup_scroll">
                  <div className="subscribe">SUBSCRIBE</div>
                  <input
                    type="email"
                    name="EMAIL"
                    placeholder=""
                    className="required email"
                    id="mce-EMAIL"
                  />
                  <div
                    style={{ position: "absolute", left: "-5000px" }}
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="b_30f169a672211d7793db6b3e5_8ef7cba9fd"
                      tabIndex="-1"
                    />
                  </div>
                  <div className="subscribeButton">
                    <input
                      type="submit"
                      value="↗"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="contacts">
            <a className="link mailTo" href="mailto:hechanyc@gmail.com">
              EMAIL
              <div className="svg-icon">
                <img src={EmailIcon} />
              </div>
            </a>
            <a className="link" href="https://www.facebook.com/hechaNY/">
              FB <span className="linkIcon arrow">→</span>
            </a>
            <a className="link" href="https://www.instagram.com/hecha_nyc">
              IG <span className="linkIcon arrow">→</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default Footer;
