import React from "react";
import { Root, Routes, withSiteData } from "react-static";

import "./reset.css";
import "./app.css";

import ShoppingCartProvider from "./contexts/shoppingCartContext2";

import Navigation from "./components/navigation/navigation";
import Footer from "./components/footer/footer";

export default withSiteData(() => (
  <Root>
    <Routes>
      {({ routePath, getComponentForPath }) => {
        const Comp = getComponentForPath(routePath);
        // custom switch-based router for handling which
        // pages get wrapped in variations on navigation/footer

        // all routes get the 'default' wrapped with navigation and footer,
        // custom routes can abstain ( like the development /components page)
        switch (routePath) {
          case "/":
            return (
              <ShoppingCartProvider>
                <div className="app">
                  <Navigation absolute />
                  <Comp />
                  <Footer absolute />
                </div>
              </ShoppingCartProvider>
            );
          //
          case "alternate":
            return (
              <div className="app">
                <Navigation />
                <Comp />
                <Footer absolute />
              </div>
            );
          default:
            return (
              <div className="app">
                <Navigation />
                <Comp className="testing" />
                <Footer />
              </div>
            );
        }
      }}
    </Routes>
  </Root>
));
