// Node Modules
import React, { Component } from "react";

import { ShoppingCartContext } from "../../contexts/shoppingCartContext2";

// Global Components
import Link from "components/link/link";
import Button from "components/button/button";
import ButtonGroup from "components/buttonGroup/buttonGroup";

// Local CSS
import "./navigation.css";
import "./navigation-mobile.css";

class Navigation extends Component {
  render() {
    return (
      <div
        className={`navigation ${
          this.props.absolute === true ? "absolute" : ""
        }`}
      >
        <ButtonGroup>
          <Button color="green">Shop</Button>
          <Button color="green">About</Button>
          <Button>Cart (0)</Button>
        </ButtonGroup>
      </div>
    );
  }
}
export default Navigation;
