
import universal, { setHasBabelPlugin } from '/opt/build/repo/node_modules/react-universal-component/dist/index.js'


setHasBabelPlugin()

const universalOptions = {
  loading: () => null,
  error: props => {
    console.error(props.error);
    return <div>An error occurred loading this page's template. More information is available in the console.</div>;
  },
}

const t_0 = universal(import('../node_modules/react-static/lib/browser/components/Default404'), universalOptions)
const t_1 = universal(import('../src/containers/home/home'), universalOptions)
const t_2 = universal(import('../src/containers/homeAlternate/homeAlternate'), universalOptions)
const t_3 = universal(import('../src/containers/products/products'), universalOptions)
const t_4 = universal(import('../src/containers/product/product'), universalOptions)
const t_5 = universal(import('../src/containers/about/about'), universalOptions)
const t_6 = universal(import('../src/containers/about/sustainability/sustainability'), universalOptions)
const t_7 = universal(import('../src/containers/about/press/press'), universalOptions)
const t_8 = universal(import('../src/containers/about/contact/contact'), universalOptions)
const t_9 = universal(import('../src/containers/about/terms/terms'), universalOptions)
const t_10 = universal(import('../src/containers/about/projects/projects'), universalOptions)
const t_11 = universal(import('../src/containers/about/bio/bio'), universalOptions)


// Template Map
export default {
  '../node_modules/react-static/lib/browser/components/Default404': t_0,
'../src/containers/home/home': t_1,
'../src/containers/homeAlternate/homeAlternate': t_2,
'../src/containers/products/products': t_3,
'../src/containers/product/product': t_4,
'../src/containers/about/about': t_5,
'../src/containers/about/sustainability/sustainability': t_6,
'../src/containers/about/press/press': t_7,
'../src/containers/about/contact/contact': t_8,
'../src/containers/about/terms/terms': t_9,
'../src/containers/about/projects/projects': t_10,
'../src/containers/about/bio/bio': t_11
}

export const notFoundTemplate = "../node_modules/react-static/lib/browser/components/Default404"
